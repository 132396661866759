@import url(https://fonts.googleapis.com/css?family=Poppins:400,500,600,700,900);
@charset "utf-8";
/* CSS Document */

/******************************

COLOR PALETTE




[Table of Contents]

1. Fonts
2. Body and some general stuff
3. Home
	3.1 Currency
	3.2 Language
	3.3 Account
	3.4 Main Navigation
	3.5 Hamburger Menu
	3.6 Logo
	3.7 Main Slider
4. Banner
5. New Arrivals
	5.1 New Arrivals Sorting
	5.2 New Arrivals Products Grid
6. Deal of the week
7. Best Sellers
	7.1 Slider Nav
8. Benefit
9. Blogs
10. Newsletter
11. Footer



******************************/

/***********
1. Fonts
***********/

/*********************************
2. Body and some general stuff
*********************************/

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  line-height: 23px;
  font-weight: 400;
  background: #ffffff;
  color: #1e1e27;
  min-height: 100vh;
}

div {
  display: block;
  position: relative;
  box-sizing: border-box;
}

ul {
  list-style: none;
  margin-bottom: 0px;
}

p {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  line-height: 1.7;
  font-weight: 500;
  color: #989898;
  -webkit-font-smoothing: antialiased;
  -webkit-text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}

p a {
  display: inline;
  position: relative;
  color: inherit;
  border-bottom: solid 2px #fde0db;
  transition: all 200ms ease;
}

a,
a:hover,
a:visited,
a:active,
a:link {
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -webkit-text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}

p a:active {
  position: relative;
  color: #ff6347;
}

p a:hover {
  color: #ff6347;
  background: #fde0db;
}

p a:hover::after {
  opacity: 0.2;
}

::selection {
  background: #fde0db;
  color: #ff6347;
}

p::selection {
  background: #fde0db;
}

h1 {
  font-size: 72px;
}

h2 {
  font-size: 40px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #282828;
  -webkit-font-smoothing: antialiased;
  -webkit-text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}

h1::selection,
h2::selection,
h3::selection,
h4::selection,
h5::selection,
h6::selection {
}

::-webkit-input-placeholder {
  font-size: 13px !important;
  font-weight: 500;
  color: #777777 !important;
}

:-moz-placeholder

/* older Firefox*/ {
  font-size: 13px !important;
  font-weight: 500;
  color: #777777 !important;
}

::-moz-placeholder

/* Firefox 19+ */ {
  font-size: 13px !important;
  font-weight: 500;
  color: #777777 !important;
}

:-ms-input-placeholder {
  font-size: 13px !important;
  font-weight: 500;
  color: #777777 !important;
}

::input-placeholder {
  font-size: 13px !important;
  font-weight: 500;
  color: #777777 !important;
}

section {
  display: block;
  position: relative;
  box-sizing: border-box;
}

.clear {
  clear: both;
}

.clearfix::before,
.clearfix::after {
  content: "";
  display: table;
}

.clearfix::after {
  clear: both;
}

.clearfix {
  zoom: 1;
}

.float_left {
  float: left;
}

.float_right {
  float: right;
}

.trans_200 {
  transition: all 200ms ease;
}

.trans_300 {
  transition: all 300ms ease;
}

.trans_400 {
  transition: all 400ms ease;
}

.trans_500 {
  transition: all 500ms ease;
}

.fill_height {
  height: 100%;
}

.super_container {
  width: 100%;
  overflow: hidden;
}

.hide {
  display: none;
  transform: translate(9999px);
  transition: all 1s linear;
  transition: opacity 1s ease-out;
  opacity: 0;
  height: 0;
  overflow: hidden;
  animation: fadeIn 1s;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(165, 164, 164);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(167, 167, 167);
}

/*********************************
3. Home
*********************************/
.layout-Container {
  min-height: calc(100vh - (150px + 1em));
  min-width: 100vw;
  margin-top: 100px;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #ffffff;
  z-index: 10;
}

.top_nav {
  width: 100%;
  height: 50px;
  background: #1e1e27;
  transition: all 2s linear;
}

.top_nav_left {
  height: 50px;
  line-height: 50px;
  font-size: 13px;
  color: #b5aec4;
  text-transform: uppercase;
}

/*********************************
3.1 Currency
*********************************/

.currency {
  display: inline-block;
  position: relative;
  min-width: 50px;
  border-right: solid 1px #33333b;
  padding-right: 20px;
  padding-left: 20px;
  text-align: center;
  vertical-align: middle;
  background: #1e1e27;
}

.currency > a {
  display: block;
  color: #b5aec4;
  font-weight: 400;
  height: 50px;
  line-height: 50px;
  font-size: 13px;
  text-transform: uppercase;
}

.currency > a > i {
  margin-left: 8px;
}

.currency:hover .currency_selection {
  visibility: visible;
  opacity: 1;
  top: 100%;
}

.currency_selection {
  display: block;
  position: absolute;
  right: 0;
  top: 120%;
  margin: 0;
  width: 100%;
  background: #ffffff;
  visibility: hidden;
  opacity: 0;
  z-index: 1;
  box-shadow: 0 0 25px rgba(63, 78, 100, 0.15);
  transition: all 0.3s ease;
}

.currency_selection li {
  padding-left: 10px;
  padding-right: 10px;
  line-height: 50px;
}

.currency_selection li a {
  display: block;
  color: #232530;
  border-bottom: solid 1px #dddddd;
  font-size: 13px;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

.currency_selection li a:hover {
  color: #b5aec4;
}

.currency_selection li:last-child a {
  border-bottom: none;
}

/*********************************
3.2 Language
*********************************/

.language {
  display: inline-block;
  position: relative;
  min-width: 50px;
  border-right: solid 1px #33333b;
  padding-right: 20px;
  padding-left: 20px;
  text-align: center;
  vertical-align: middle;
  background: #1e1e27;
}

.language > a {
  display: block;
  color: #b5aec4;
  font-weight: 400;
  height: 50px;
  line-height: 50px;
  font-size: 13px;
}

.language > a > i {
  margin-left: 8px;
}

.language:hover .language_selection {
  visibility: visible;
  opacity: 1;
  top: 100%;
}

.language_selection {
  display: block;
  position: absolute;
  right: 0;
  top: 120%;
  margin: 0;
  width: 100%;
  background: #ffffff;
  visibility: hidden;
  opacity: 0;
  z-index: 1;
  box-shadow: 0 0 25px rgba(63, 78, 100, 0.15);
  transition: all 0.3s ease;
}

.language_selection li {
  padding-left: 10px;
  padding-right: 10px;
  line-height: 50px;
}

.language_selection li a {
  display: block;
  color: #232530;
  border-bottom: solid 1px #dddddd;
  font-size: 13px;
  transition: all 0.3s ease;
}

.language_selection li a:hover {
  color: #b5aec4;
}

.language_selection li:last-child a {
  border-bottom: none;
}

/*********************************
3.3 Account
*********************************/

.account {
  display: inline-block;
  position: relative;
  min-width: 50px;
  padding-left: 20px;
  text-align: center;
  vertical-align: middle;
  background: #1e1e27;
}

.account > a {
  display: block;
  color: #b5aec4;
  font-weight: 400;
  height: 50px;
  line-height: 50px;
  font-size: 13px;
}

.account > a:hover {
  text-decoration: none;
}

.account > a > i {
  margin-left: 8px;
}

.account:hover .account_selection {
  visibility: visible;
  opacity: 1;
  top: 100%;
}

.account_selection {
  display: block;
  position: absolute;
  right: 0;
  top: 120%;
  margin: 0;
  width: 100%;
  background: #ffffff;
  visibility: hidden;
  opacity: 0;
  z-index: 1;
  box-shadow: 0 0 25px rgba(63, 78, 100, 0.15);
  transition: all 0.3s ease;
}

.account_selection li {
  padding-left: 10px;
  padding-right: 10px;
  line-height: 50px;
}

.account_selection li a {
  display: block;
  color: #232530;
  border-bottom: solid 1px #dddddd;
  font-size: 14px;
  transition: all 0.3s ease;
}

.account_selection li a:hover {
  color: #b5aec4;
  text-decoration: none;
}

.account_selection li:last-child a {
  border-bottom: none;
}

.account_selection li a i {
  margin-right: 10px;
}

/*********************************
3.4 Main Navigation
*********************************/

.main_nav_container {
  width: 100%;
  background: #ffffff;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.15);
}

.navbar {
  width: auto;
  height: 100px;
  float: right;
  padding-left: 0px;
  padding-right: 0px;
}

.navbar_menu li {
  display: inline-block;
}

.navbar_menu li a {
  display: block;
  color: #121212;
  font-size: 14px;
  font-weight: 600;
  line-height: 27px;
  text-transform: uppercase;
  padding: 20px;
  text-decoration: none;
  transition: color 0.3s ease;
}

.navbar_user {
  margin-left: 37px;
}

.navbar_user li {
  display: inline-block;
  text-align: center;
}

.navbar_user li a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  text-decoration: none;
  width: auto;
  height: 40px;
  color: #1e1e27;
  transition: color 0.3s ease;
}

.navbar_user li a:hover {
  color: #b5aec4;
  text-decoration: none;
}

.checkout a {
  background: #eceff6;
  border-radius: 50%;
}

.checkout_items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -9px;
  left: 22px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fe4c50;
  font-size: 12px;
  color: #ffffff;
}

/*********************************
3.5 Hamburger Menu
*********************************/

.hamburger_container {
  display: none;
  margin-left: 40px;
}

.hamburger_container i {
  font-size: 24px;
  color: #1e1e27;
  transition: all 300ms ease;
}

.hamburger_container:hover i {
  color: #b5aec4;
}

.hamburger_menu {
  position: fixed;
  top: 0;
  right: -400px;
  width: 400px;
  height: 100vh;
  background: rgba(255, 255, 255, 0.95);
  z-index: 10;
  transition: all 300ms ease;
}

.hamburger_menu.active {
  right: 0;
}

.fs_menu_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9;
  background: rgba(255, 255, 255, 0);
  pointer-events: none;
  transition: all 200ms ease;
}

.hamburger_close {
  position: absolute;
  top: 26px;
  right: 10px;
  padding: 10px;
  z-index: 1;
}

.hamburger_close i {
  font-size: 24px;
  color: #1e1e27;
  transition: all 300ms ease;
}

.hamburger_close:hover i {
  color: #b5aec4;
}

.hamburger_menu_content {
  padding-top: 100px;
  width: 100%;
  height: 100%;
  padding-right: 20px;
}

.menu_item {
  display: block;
  position: relative;
  border-bottom: solid 1px #b5aec4;
  vertical-align: middle;
}

.menu_item > a {
  display: block;
  color: #1e1e27;
  font-weight: 500;
  height: 50px;
  line-height: 50px;
  font-size: 14px;
  text-transform: uppercase;
}

.menu_item > a:hover {
  color: #b5aec4;
}

.menu_item > a > i {
  margin-left: 8px;
}

.menu_item.active .menu_selection {
  display: block;
  visibility: visible;
  opacity: 1;
  color: "#FABA1C";
}

.menu_selection {
  margin: 0;
  width: 100%;
  max-height: 0;
  overflow: hidden;
  z-index: 1;
  transition: all 0.3s ease;
}

.menu_selection li {
  padding-left: 10px;
  padding-right: 10px;
  line-height: 50px;
}

.menu_selection li a {
  display: block;
  color: #232530;
  border-bottom: solid 1px #dddddd;
  font-size: 13px;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

.menu_selection li a:hover {
  color: #b5aec4;
}

.menu_selection li:last-child a {
  border-bottom: none;
}

/*********************************
3.6 Logo
*********************************/

.logo_container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
}

.logo_container a {
  font-size: 24px;
  color: #1e1e27;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
}

.logo_container a span {
  color: #fe4c50;
  text-decoration: none;
}

.logo_container a:hover {
  color: #1e1e27;
  text-decoration: none;
}

/*********************************
3.7 Main Slider
*********************************/

.main_slider {
  width: 100%;
  height: 500px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.main_slider_content {
  width: 60%;
}

.main_slider_content h6 {
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 29px;
}

.main_slider_content h1 {
  font-weight: 400;
  line-height: 1;
}

.red_button {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 40px;
  background: #fbba1b;
  border-radius: 3px;
  transition: all 0.3s ease;
}

.red_button:hover {
  background: #191364 !important;
}

.red_button a {
  display: block;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  line-height: 40px;
  width: 100%;
}

.shop_now_button {
  width: 140px;
  margin-top: 32px;
}

/*********************************
4. Banner
*********************************/

.banner {
  width: 100%;
  margin-top: 30px;
}

.banner_item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 265px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.banner_category {
  height: 50px;
  background: #ffffff;
  min-width: 180px;
  padding-left: 25px;
  padding-right: 25px;
}

.banner_category a {
  display: block;
  color: #1e1e27;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  line-height: 50px;
  width: 100%;
  transition: color 0.3s ease;
}

.banner_category a:hover {
  color: #b5aec4;
}

/*********************************
5. New Arrivals
*********************************/

.new_arrivals {
  width: 100%;
}

.new_arrivals_title {
  margin-top: 74px;
}

/*********************************
5.1 New Arrivals Sorting
*********************************/

.new_arrivals_sorting {
  display: inline-block;
  border-radius: 3px;
  margin-top: 59px;
  overflow: visible;
}

.grid_sorting_button {
  height: 40px;
  min-width: 102px;
  padding-left: 25px;
  padding-right: 25px;
  cursor: pointer;
  border: solid 1px #ebebeb;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  background: #ffffff;
  border-radius: 3px;
  margin: 0;
  float: left;
  margin-left: -1px;
}

.grid_sorting_button:first-child {
  margin-left: 0px;
}

.grid_sorting_button:last-child {
}

.grid_sorting_button.active:hover {
  background: #fe7c7f !important;
}

.grid_sorting_button.active {
  color: #ffffff;
  background: #fe4c50;
}

/*********************************
5.2 New Arrivals Products Grid
*********************************/

.product-grid {
  width: 100%;
  margin-top: 57px;
}

.col-lg-3.col-sm-6.aos-init.aos-animate {
  padding-bottom: 3em !important;
}

.product-item {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  /* cursor: pointer; */
  border-radius: 4px;
  width: 100%;
  padding: 8px;
}

.product-item::after {
  display: block;
  position: absolute;
  top: 0;
  left: -1px;
  width: calc(100% + 1px);
  height: 100%;
  pointer-events: none;
  content: "";
  border: solid 2px rgba(235, 235, 235, 0);
  border-radius: 3px;
  transition: all 0.3s ease;
}

.product-item:hover::after {
  box-shadow: 0 25px 29px rgba(0, 0, 0, 0.3);
  border: solid 2px rgba(235, 235, 235, 1);
  border-radius: 4px;
}

.product {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #f2f2f261;
}

.product_image {
  width: 100%;
  /* height: 279px; */
  display: flex;
  justify-content: center;
  cursor: pointer;
  aspect-ratio: 1;
}

.product_image img {
  /* max-width: 100%;
  max-height: 100%; */
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 4px;
  aspect-ratio: 1;
}

.product_info {
  text-align: start;
}

.product_name {
  margin-top: 12px;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: black;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product_name a {
  color: #1e1e27;
  line-height: 20px;
}

.product_name:hover a {
  color: #b5aec4;
}

.discount .product_info .product_price {
  font-size: 12px;
}

.product_price {
  font-size: 14px;
  color: #303030;
  font-weight: 700;
  line-height: 27px;
  margin-top: 22px;
}

.product_price span {
  font-size: 12px;
  margin-left: 10px;
  color: #b5aec4;
  text-decoration: line-through;
}

.product_bubble {
  position: absolute;
  top: 15px;
  width: 50px;
  height: 22px;
  border-radius: 3px;
  text-transform: uppercase;
}

.product_bubble span {
  font-size: 12px;
  font-weight: 600;
  color: #ffffff;
}

.product_bubble_right::after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: 6px;
  right: auto;
  top: auto;
  bottom: -6px;
  border: 6px solid;
}

.product_bubble_left::after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: 6px;
  bottom: -6px;
  border: 6px solid;
}

.product_bubble_red::after {
  border-color: transparent transparent transparent #fe4c50;
}

.product_bubble_left::after {
  border-color: #51a042 #51a042 transparent transparent;
}

.product_bubble_red {
  background: #fe4c50;
}

.product_bubble_green {
  background: #51a042;
}

.product_bubble_left {
  left: 15px;
}

.product_bubble_right {
  right: 15px;
}

.add_to_cart_button {
  width: 100%;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;
}

.add_to_cart_button a {
  font-size: 12px;
  font-weight: 600;
}

.product-item:hover .add_to_cart_button {
  visibility: visible;
  opacity: 1;
}

.favorite {
  position: absolute;
  top: 15px;
  right: 15px;
  color: #b9b4c7;
  width: 17px;
  height: 17px;
  visibility: hidden;
  opacity: 0;
}

.favorite_left {
  /* left: 15px; */
  top: 10px;
  right: 8px;
  color: #faba1c;
  width: 21px;
  height: 17px;
}

.favorite.active {
  visibility: visible;
  opacity: 1;
}

.product-item:hover .favorite {
  visibility: visible;
  opacity: 1;
}

.favorite.active::after {
  color: #faba1c;
  /* fill: #faba1c; */
}

.favorite:hover {
  color: #fe4c50;
}

/*********************************
6. Deal of the week
*********************************/

.deal_ofthe_week {
  width: 100%;
  margin-top: 41px;
  background: #f2f2f2;
}

.deal_ofthe_week_img {
  height: 540px;
}

.deal_ofthe_week_img img {
  height: 100%;
}

.deal_ofthe_week_content {
  height: 100%;
}

.section_title {
  display: inline-block;
  text-align: center;
}

.section_title h2 {
  display: inline-block;
}

.section_title::after {
  display: block;
  position: absolute;
  top: calc(100% + 13px);
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 5px;
  background: #fbba1b;
  content: "";
}

.timer {
  margin-top: 66px;
}

.timer li {
  display: inline-block;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: #ffffff;
  margin-right: 10px;
}

.timer li:last-child {
  margin-right: 0px;
}

.timer_num {
  font-size: 48px;
  font-weight: 600;
  color: #fe4c50;
  margin-top: 10px;
}

.timer_unit {
  margin-top: 12px;
  font-size: 16px;
  font-weight: 500;
  color: #51545f;
}

.deal_ofthe_week_button {
  width: 140px;
  background: #1e1e27;
  margin-top: 52px;
}

.deal_ofthe_week_button:hover {
  background: #2b2b34 !important;
}

/*********************************
7. Best Sellers
*********************************/

.product_slider_container {
  width: 100%;
  height: 340px;
  margin-top: 73px;
}

.product_slider_container::after {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  height: 100%;
  background: #ffffff;
  content: "";
  z-index: 1;
}

.product_slider {
  overflow: visible !important;
}

.product_slider_item .product-item {
  height: 340px;
}

.product_slider_item {
  width: 20%;
}

.product_slider_item .product-item {
  width: 100% !important;
}

/*********************************
7.1 Slider Nav
*********************************/

.product_slider_nav {
  position: absolute;
  width: 30px;
  height: 70px;
  background: #d3d3d6;
  transform: translateY(-50%);
  z-index: 2;
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  transition: all 300ms ease;
}

.product_slider_container:hover .product_slider_nav {
  visibility: visible;
  opacity: 1;
}

.product_slider_nav i {
  color: #ffffff;
  font-size: 12px;
}

.product_slider_nav_left {
  top: 50%;
  left: 0;
}

.product_slider_nav_right {
  top: 50%;
  right: 0;
}

/*********************************
8. Benefit
*********************************/

.benefit {
  margin-top: 74px;
}

.benefit_row {
  display: none;
  background-color: #f3f3f3;
}

.row.benefit_row {
  display: none !important;
}

.benefit_col {
  padding-left: 0px;
  padding-right: 0px;
}

.benefit_item {
  height: 100px;
  background: #f3f3f3;
  border-right: solid 1px #ffffff;
  padding-left: 25px;
}

.benefit_col:last-child .benefit_item {
  border-right: none;
}

.benefit_icon i {
  font-size: 30px;
  color: #fe4c50;
}

.benefit_content {
  padding-left: 22px;
}

.benefit_content h6 {
  text-transform: uppercase;
  line-height: 18px;
  font-weight: 500;
  margin-bottom: 0px;
}

.benefit_content p {
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 0px;
  color: #51545f;
}

/*********************************
4. Breadcrumbs
*********************************/

.breadcrumbs {
  width: 100%;
  height: 60px;
  border-bottom: solid 1px #ebebeb;
  margin-bottom: 20px;
}

.breadcrumbs ul li {
  display: inline-block;
  margin-right: 17px;
}

.breadcrumbs ul li.active a {
  color: #b9b4c7;
}

.breadcrumbs ul li i {
  margin-right: 17px;
}

.breadcrumbs ul li a {
  color: #51545f;
}

/*********************************
9. single product
*********************************/
.single_product_container {
  margin-top: 150px;
  padding-bottom: 80px;
  border-bottom: solid 1px #ebebeb;
}

.single_product_thumbnails {
  height: 527px;
}

.single_product_thumbnails ul li {
  position: relative;
  height: 130px;
  margin-bottom: 6px;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.single_product_thumbnails ul li::after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: solid 1px #fe4c50;
  content: "";
  visibility: hidden;
}

.single_product_thumbnails ul li.active::after,
.single_product_thumbnails ul li:hover::after {
  visibility: visible;
}

.single_product_thumbnails ul li img {
  width: auto;
  text-align: center;
  max-width: 130px;
  max-height: 130px;
}

.image_col {
  padding-right: 30px;
}

.single_product_image {
  width: 100%;
  height: 527px;
}

.single_product_image_background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

/*********************************
6. Product Details
*********************************/

.product_details {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 12px;
  gap: 12px;
}

.product_details_title {
  font-weight: 500px;
  line-height: 30px;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}
.product_details_title h2 {
  text-transform: capitalize;
}

.product_details_title p {
  margin-top: 12px;
}

.free_delivery {
  background: #f5f5f5;
  margin-top: 53px;
}

.free_delivery i {
  display: inline-block;
}

.free_delivery span:last-child {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  text-transform: uppercase;
}

.free_delivery span:first-child {
  display: inline-block;
  margin-right: 19px;
  font-size: 16px;
  transform: translateY(-1px);
}

.original_price {
  font-weight: 500;
  /* color: #b9b4c7; */
  /* text-decoration: line-through; */
  line-height: 30px;
  margin-top: 21px;
  border: 1px solid;
  border-color: #f0b21e;
  border-radius: 4px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 4px;
  display: flex;
}

.star_rating {
  display: inline-block;
  margin-left: 33px;
  width: 20%;
}

.star_rating li {
  display: inline-block;
  margin-right: -2px;
  cursor: pointer;
}

.star_rating li i {
  color: #fac451;
}

.product_color {
  /* top: 326px; */
  margin-top: -8px;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;
  grid-gap: 6px;
  gap: 6px;
  height: 20px;
}
.product_color_item {
  width: 48px;
  height: 16px;
  border-radius: 4px;
  cursor: pointer;
}

.product_color ul {
  display: inline-block;
  margin-left: 26px;
}

.product_color ul li {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 29px;
  cursor: pointer;
}

.quantity {
  width: 100%;
  height: 40px;
  margin-top: 23px;
}

.quantity_selector {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 132px;
  height: 100%;
  border: solid 1px #e5e5e5;
  /* margin-left: 29px; */
}

.quantity_selector span {
  font-weight: 500;
  color: #232530;
}

.plus,
.minus {
  padding-left: 14px;
  padding-right: 14px;
  cursor: pointer;
}

.plus:hover,
.minus:hover {
  color: #b5aec4;
}

.add_to_cart_button {
  font-size: 12px !important;
}

.product_favorite {
  width: 40px;
  height: 100%;
  /* margin-left: 22px; */
  border: solid 1px #e5e5e5;
  border-radius: 2px;
  cursor: pointer;
}

.product_favorite:hover::after {
  color: #fe4c50;
}

.filter_option {
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
}

.filter_option:hover {
  background: #fe4c50;
}

.red_button.product-add_to_cart_button {
  display: block;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  line-height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
}

.red_button.product-add_to_cart_button a:hover {
  text-decoration: none;
  color: white;
}

.red_cart_button.product_add_to_cart_icon {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 40px;
  background: #fe4c50;
  color: white;
  border-radius: 3px;
  padding-left: 10px;
  padding-right: 10px;
  transition: all 0.3s ease;
}

span.minus.disabled {
  color: #b5aec4;
  pointer-events: none;
  cursor: none;
}

/*********************************
10. Newsletter
*********************************/

.newsletter {
  width: 100%;
  background: #f2f2f2;
  margin-top: 81px;
}

.newsletter_text {
  height: 120px;
}

.newsletter_text h4 {
  margin-bottom: 0px;
  line-height: 24px;
}

.newsletter_text p {
  margin-bottom: 0px;
  line-height: 1.7;
  color: #51545f;
}

.newsletter_form {
  height: 120px;
}

#newsletter_email {
  width: 300px;
  height: 46px;
  background: #ffffff;
  border: none;
  padding-left: 20px;
}

#newsletter_email:focus {
  border: solid 1px #7f7f7f !important;
  box-shadow: none !important;
}

:focus {
  outline: none !important;
}

#newsletter_email::-webkit-input-placeholder {
  font-size: 13px !important;
  font-weight: 400;
  color: #b9b4c7 !important;
}

#newsletter_email:-moz-placeholder

/* older Firefox*/ {
  font-size: 13px !important;
  font-weight: 400;
  color: #b9b4c7 !important;
}

#newsletter_email::-moz-placeholder

/* Firefox 19+ */ {
  font-size: 13px !important;
  font-weight: 400;
  color: #b9b4c7 !important;
}

#newsletter_email:-ms-input-placeholder {
  font-size: 13px !important;
  font-weight: 400;
  color: #b9b4c7 !important;
}

#newsletter_email::input-placeholder {
  font-size: 13px !important;
  font-weight: 400;
  color: #b9b4c7 !important;
}

.newsletter_submit_btn {
  width: 160px;
  height: 46px;
  border: none;
  background: #fe4c50;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
}

.newsletter_submit_btn:hover {
  background: #fe7c7f;
}

/*********************************
11. Footer
*********************************/

footer.footer {
  margin-top: 50px;
  background: #f0f0f0;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
}

.footer_nav_container {
  height: 60px;
}

.cr {
  margin-right: 48px;
}

.footer_nav li {
  display: inline-block;
  margin-right: 45px;
}

.footer_nav li:last-child:not(.marg) {
  margin-right: 0px;
}

.footer_nav li.marg {
  margin-right: 45px !important;
}

.footer_nav li a,
.cr {
  font-size: 14px;
  color: #5b5b5b;
  font-weight: 600;
  line-height: 27px;
  transition: all 300ms ease;
}

.footer_nav li a:hover,
.cr a,
.cr i {
  color: #fe4c50;
}

.footer_social {
  height: 60px;
}

.footer_social ul li {
  display: inline-block;
  margin-right: 10px;
}

.footer_social ul li:last-child {
  margin-right: 0px;
}

.footer_social ul li a {
  color: #51545f;
  transition: all 300ms ease;
  padding: 10px;
}

.footer_social ul li:last-child a {
  padding-right: 0px;
}

.footer_social ul li a:hover {
  color: #fe4c50;
}

a:hover {
  text-decoration: none;
}

.mega-drop-down {
  /* display: inline-block;
  position: relative;
  min-width: 50px;
  padding-left: 20px;
  text-align: center;
  vertical-align: middle; */
}

.mega-menu {
  background: #ffffff;
  visibility: hidden;
  opacity: 0;
  z-index: 1;
  box-shadow: 0 0 25px rgba(63, 78, 100, 0.55);
  transition: all 0.3s ease;
  padding: 10px 10px 10px 10px;
  padding-top: 0;
  position: absolute;
  border-radius: 12px;
}

.mega-drop-down:hover .mega-menu {
  visibility: visible;
  opacity: 1;
  /* top: 40%; */
  box-sizing: content-box;
}

.mega-menu-wrap {
  display: flex;
  flex-direction: row;
}

.mega-menu-content {
  /* text-align: left; */
  /* margin-right: 20px; */
  margin-top: 20px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 250px;
}

ul.stander {
  flex-direction: column;
  display: flex;
}

ul.stander li a {
  padding: 10px;
}

.empty--basket {
  height: 100%;
  margin-top: 25%;
}

.empty--basket > h4 {
  color: #82828285;
  margin-bottom: 20px;
}

.basket--item {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  margin-top: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ebebeb;
  grid-gap: 6px;
  gap: 6px;
}

.basket--item--img {
  width: 100px;
  height: 100px;
  border-radius: 8px;
}

.basket--item--img > img {
  width: 100px;
  height: 100px;
  border-radius: 8px;
}

.basket--item--title {
  font-size: 14px;
  font-weight: 700;
  color: #2c2c2c;
}

.basket--item--quantity {
  font-size: 16px;
}

.basket--item--quantity > span {
  font-size: 16px;
  font-weight: 500;
}

.basket--item--price {
  font-size: 16px;
}

.basket--item--price > span {
  font-size: 16px;
  font-weight: 500;
}

.modal-header > .checkout--btn {
  /* position: absolute; */
  /* right: 0;
  margin-right: 50px;*/
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 7px;
  cursor: pointer;
  background-color: #db5246;
  color: white;
  border-radius: 30px;
  text-align: center;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.error-container {
  height: 100vh !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: montserrat, sans-serif;
}

.big-text {
  font-size: 200px;
  font-weight: 900;
  font-family: sans-serif;
  background: url(https://cdn.pixabay.com/photo/2018/05/30/15/39/thunderstorm-3441687_960_720.jpg)
    no-repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: cover;
  background-position: center;
}

.small-text {
  font-family: montserrat, sans-serif;
  color: rgb(0, 0, 0);
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
}

.home-page-button {
  color: #fff;
  padding: 12px 36px;
  font-weight: 600;
  border: none;
  position: relative;
  font-family: "Raleway", sans-serif;
  display: inline-block;
  text-transform: uppercase;
  border-radius: 90px;
  margin: 2px;
  margin-top: 2px;
  background-image: linear-gradient(
    to right,
    #09b3ef 0%,
    #1e50e2 51%,
    #09b3ef 100%
  );
  background-size: 200% auto;
  flex: 1 1 auto;
  text-decoration: none;
}

.home-page-button:hover,
.home-page-button:focus {
  color: #ffffff;
  background-position: right center;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
  text-decoration: none;
}

/*********************************
6. Sidebar
*********************************/

.product_section_container {
  margin-top: 150px;
}

.product_section {
}

.sidebar {
}

.sidebar_section {
  padding-bottom: 35px;
  margin-bottom: 50px;
  border-bottom: solid 1px #ebebeb;
}

.sidebar_title h5 {
  font-size: 18px;
  margin-bottom: 21px;
}

.sidebar_categories li.active a {
  color: #fbba1b;
}

.sidebar_categories li a {
  font-weight: bold;
  font-size: 1em;
  margin-left: 0.2em;
}

.sidebar_categories li.active:hover a {
  color: #191364;
}

.sidebar_categories li a {
  font-size: 14px;
  line-height: 40px;
  color: #51545f;
}

.sidebar_categories li:hover {
  border: 1px solid;
  border-radius: 16px;
  border-color: #fbba1b;
  padding-left: 4px;
  padding-right: 4px;
}
.sidebar_categories li a:hover {
  color: #b5aec4;
}

.sidebar_categories li a span {
  margin-right: 5px;
}

#slider-range {
  margin-left: 7px !important;
}

#amount {
  margin-top: 8px;
  color: #1e1e27 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}

.filter_button {
  width: 100%;
  height: 30px;
  background: #1e1e27;
  text-align: center;
  margin-top: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.filter_button:hover {
  background: #34343f;
}

.filter_button span {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 30px;
  color: #ffffff;
}

.checkboxes li {
  height: 40px;
  line-height: 40px;
}

.checkboxes li.active i {
  color: #fe4c50;
  margin-right: 21px;
}

.checkboxes li.active i::after {
  display: block;
  position: absolute;
  top: 1px;
  left: 1px;
  font-family: "FontAwesome";
  font-size: 10px;
  content: "\f00c";
  color: #ffffff;
}

.checkboxes {
  height: 200px;
  overflow: hidden;
}

.checkboxes.active {
  height: auto;
}

.checkboxes li i {
  position: relative;
  color: #b3b7c8;
  cursor: pointer;
  margin-right: 22px;
}

.checkboxes li span {
  font-family: "Poppins", sans-serif;
  color: #51545f;
}

.show_more {
  display: inline-block;
  cursor: pointer;
}

.show_more span {
  height: 40px;
  line-height: 40px;
  color: #fe4c50;
}

.show_more span:first-child {
  margin-right: 11px;
}

/*********************************
4.1 Product Sorting
*********************************/

.product_sorting_container_bottom {
  margin-top: 24px;
}

.product_sorting_container_bottom .product_sorting {
  float: right;
}

.product_sorting {
  display: inline-block;
}

.product_sorting > li {
  display: inline-block;
  position: relative;
  width: 185px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  border: solid 1px #ecedee;
  padding-left: 20px;
  padding-right: 7px;
  margin-right: 16px;
}

.product_sorting_container_bottom .product_sorting > li {
  width: 144px;
  margin-right: 0px;
  padding-right: 12px;
}

.product_sorting_container_bottom .product_sorting li span:nth-child(2) {
  margin-left: 24px;
}

.product_sorting li i {
  float: right;
  line-height: 40px;
  color: #51545f;
}

.product_sorting li ul {
  display: block;
  position: absolute;
  right: 0;
  top: 120%;
  margin: 0;
  width: 100%;
  background: #ffffff;
  visibility: hidden;
  opacity: 0;
  z-index: 1;
  box-shadow: 0 15px 25px rgba(63, 78, 100, 0.15);
  transition: all 0.3s ease;
}

.product_sorting > li:hover ul {
  visibility: visible;
  opacity: 1;
  top: calc(100% + 1px);
}

.product_sorting li ul li {
  display: block;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}

.product_sorting li ul li span {
  display: block;
  height: 40px;
  line-height: 40px;
  border-bottom: solid 1px #dddddd;
  color: #51545f;
  transition: all 0.3s ease;
}

.product_sorting li ul li span:hover {
  color: #b5aec4;
}

.product_sorting li ul li:last-child span {
  border-bottom: none;
}

.product_sorting li span:nth-child(2) {
  margin-left: 71px;
}

.pages {
  display: inline-block;
  float: right;
}

.product_sorting_container_bottom .pages {
  float: left;
}

.page_current {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  text-align: center;
  border: solid 1px #ecedee;
  margin-right: 16px;
}

.product_sorting_container_bottom .pages .page_current {
  background: #fe4c50;
  border: none;
}

.page_current span {
  color: #51545f;
}

.product_sorting_container_bottom .pages .page_current span {
  color: #ffffff;
}

.page_selection {
  display: block;
  position: absolute;
  right: 0;
  top: 120%;
  margin: 0;
  width: 100%;
  background: #ffffff;
  visibility: hidden;
  opacity: 0;
  z-index: 1;
  box-shadow: 0 15px 25px rgba(63, 78, 100, 0.15);
  transition: all 0.3s ease;
}

.page_selection li {
  display: block;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}

.page_selection li a {
  display: block;
  height: 40px;
  line-height: 40px;
  border-bottom: solid 1px #dddddd;
  color: #51545f;
  transition: all 0.3s ease;
}

.page_selection li a:hover {
  color: #b5aec4;
}

.page_current:hover .page_selection {
  visibility: visible;
  opacity: 1;
  top: calc(100% + 1px);
}

.page_total {
  display: inline-block;
  line-height: 40px;
  margin-right: 31px;
}

.page_total span {
  margin-right: 14px;
}

.page_next {
  display: inline-block;
  line-height: 40px;
  cursor: pointer;
}

.page_next:hover i {
  color: #b5aec4;
}

.page_next i {
  font-size: 18px;
  color: #51545f;
}

.showing_results {
  font-size: 16px;
  height: 40px;
  line-height: 40px;
  float: right;
  margin-right: 34px;
}

.main_nav_container {
  background: white;
}

/* .navbar_menu li a:not(.) {
  color: #121212 !important;
} */

.red_button {
  background: #fbba1b;
}

.grid_sorting_button.active {
  background: #fbba1b !important;
}

.grid_sorting_button.active:hover {
  background: #fbba1b !important;
}

.logo-img {
  width: 69.33px;
  height: 53.33px;
}

.navbar_menu li a.active {
  color: #fbba1b !important;
}

.navbar_menu li a:hover {
  color: #fbba1b !important;
}

@media only screen and (max-width: 600px) {
  .logo-img {
    width: 69.33px;
    height: 53.33px;
  }
}

.react-dropdown-select
  .react-dropdown-select-content
  .react-dropdown-select-arrow {
  color: red; /* Change the color of the arrow */
  transform: rotate(45deg); /* Rotate the arrow */
}

@charset "utf-8";
/* CSS Document */

/******************************

[Table of Contents]

1. 1600px
2. 1440px
3. 1280px
4. 1199px
5. 1024px
6. 991px
7. 959px
8. 880px
9. 768px
10. 767px
11. 539px
12. 479px
13. 400px

******************************/

@media only screen and (max-width: 1600px) {
}

/************
2. 1440px
************/

@media only screen and (max-width: 1540px) {
}

/************
3. 1380px
************/

@media only screen and (max-width: 1380px) {
}

/************
3. 1280px
************/

@media only screen and (max-width: 1280px) {
}

/************
4. 1199px
************/

@media only screen and (max-width: 1199px) {
  .main_slider {
    min-height: 475px;
    height: calc(100vw / 1.714);
  }
  .main_slider_content {
    width: 80%;
  }
  .banner_item {
    height: 220px;
  }
  .product-item {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .product {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .timer li {
    width: 90px;
    height: 90px;
  }
  .timer_num {
    font-size: 36px;
    font-weight: 500;
  }
  .timer_unit {
    margin-top: 5px;
  }
  .blog_title {
    font-size: 20px;
  }
  .product_name {
    font-size: 13px;
  }
}

/************
5. 1024px
************/

@media only screen and (max-width: 1024px) {
}

/************
6. 991px
************/

@media only screen and (max-width: 991px) {
  h1 {
    font-size: 48px;
  }
  h2 {
    font-size: 36px;
  }
  .main_slider_content h6 {
    margin-bottom: 23px;
  }
  .shop_now_button {
    margin-top: 26px;
  }
  .grid-item {
    width: 25%;
  }
  .top_nav {
    display: none;
  }
  .navbar_menu {
    display: none;
  }
  .hamburger_container {
    display: block;
  }
  .main_slider {
    margin-top: -50px;
  }
  .main_slider_content {
    width: 100%;
    padding-right: 15px;
  }
  .banner_item {
    height: 160px;
  }
  .banner_category {
    min-width: 160px;
    height: 40px;
  }
  .banner_category a {
    font-size: 16px;
    line-height: 40px;
  }

  .deal_ofthe_week_col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .deal_ofthe_week_content {
    position: relative;
    top: auto;
    left: auto;
    height: auto;
  }
  .deal_ofthe_week_img {
    text-align: center;
  }
  .timer li {
    width: 70px;
    height: 70px;
  }
  .timer_num {
    font-size: 28px;
  }
  .timer_unit {
    margin-top: 1px;
    font-size: 14px;
  }
  .benefit_col {
    margin-bottom: 30px;
  }
  .benefit_col:last-child {
    margin-bottom: 0px;
  }
  .blog_item_col {
    margin-bottom: 30px;
  }
  .blog_item_col:last-child {
    margin-bottom: 0px;
  }
  .blog_item {
    height: 440px;
  }
  .blog_title {
    font-size: 24px;
  }
  .newsletter_text {
    height: auto;
    margin-top: 30px;
  }
  .newsletter_text p {
    margin-top: 5px;
  }
  .newsletter_form {
    height: auto;
    margin-top: 30px;
    margin-bottom: 40px;
  }
  .footer_nav_container {
    height: auto;
    margin-top: 65px;
  }
  .footer_social {
    height: auto;
    margin-top: 20px;
    margin-bottom: 65px;
  }

  .single_product_thumbnails > ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex: auto;
  }
  .single_product_thumbnails {
    height: auto;
  }
  .single_product_thumbnails ul li {
    position: relative;
    height: 130px;
    width: 20%;
    margin-bottom: 6px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  .product_name {
    font-size: 12px;
  }
}

/************
7. 959px
************/

@media only screen and (max-width: 959px) {
}

/************
8. 880px
************/

@media only screen and (max-width: 880px) {
}

/************
9. 768px
************/

@media only screen and (max-width: 768px) {
  .product_name {
    font-size: 13px;
  }
}

/************
10. 767px
************/

@media only screen and (max-width: 767px) {
  h1 {
    font-size: 36px;
  }
  h2 {
    font-size: 24px;
  }
  h6 {
    font-size: 12px;
  }
  .main_slider_content h6 {
    margin-bottom: 20px;
  }
  .shop_now_button {
    margin-top: 23px;
  }
  .red_button a {
    font-size: 12px;
  }
  .banner_item {
    margin-bottom: 30px;
    height: 210px;
  }
  .new_arrivals_title {
    margin-top: 44px;
  }
  .product-item {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .product {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .grid_sorting_button {
    font-size: 13px;
    padding-left: 20px;
    padding-right: 20px;
    min-width: 80px;
  }
  .product_slider_container {
    height: auto;
  }
  .product_slider_item .product-item {
    height: 380px;
  }
  .benefit_col {
    margin-bottom: 15px;
  }
  .blog_item {
    height: 372px;
  }
  .newsletter_form {
    margin-bottom: 40px;
  }
  .newsletter_submit_btn {
    margin-top: 15px;
  }
  .product_image img {
    max-width: 100%; /* Ensures image doesn't exceed container width */
    /* object-fit: contain; */
  }
}

/************
11. 575px
************/

@media only screen and (max-width: 575px) {
  .hamburger_menu {
    right: -100%;
    width: 100%;
  }
  .product-item {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .product {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .blog_item {
    height: calc((100vw - 30px) / 1.37);
  }
  .cr {
    display: block;
    margin-right: 0px;
    margin-bottom: 30px;
    font-size: 13px;
  }
  .footer_nav {
    margin-bottom: 20px;
  }
  .footer_nav li {
    display: block;
    margin-right: 0px;
  }
  .footer_nav li a {
    font-size: 13px;
  }
  .product_name {
    font-size: 16px;
  }
}

/************
11. 539px
************/

@media only screen and (max-width: 539px) {
}

/************
12. 480px
************/

@media only screen and (max-width: 480px) {
}

/************
13. 479px
************/

@media only screen and (max-width: 479px) {
  .logo_container a {
    font-size: 12px;
  }
  .hamburger_container {
    margin-left: 25px;
  }
  .hamburger_container i {
    font-size: 16px;
  }
  .hamburger_close {
    top: 14px;
    right: 4px;
  }
  .hamburger_close i {
    font-size: 20px;
  }
  .hamburger_menu_content {
    padding-right: 15px;
    padding-top: 70px;
  }
  .menu_item {
    border-bottom-color: rgba(181, 174, 196, 0.5);
  }
  .menu_item > a {
    font-size: 12px;
    line-height: 35px;
    height: 35px;
  }
  .menu_selection li a {
    font-size: 12px;
    line-height: 35px;
    height: 35px;
  }
  .navbar {
    height: 70px;
  }
  .navbar_user li a {
    width: auto;
    height: 30px;
    font-size: 12px;
  }
  .checkout_items {
    width: 15px;
    height: 15px;
    font-size: 10px;
  }
  .main_slider {
    height: calc(100vh - 70px);
    /*height: 80vw;*/
    min-height: auto;
    margin-top: 70px;
  }
  .main_slider_content h6 {
    margin-bottom: 15px;
  }
  .main_slider_content h1 {
    font-size: 24px;
  }
  .shop_now_button {
    margin-top: 15px;
    width: 100px;
    height: 35px;
  }
  .red_button a {
    font-size: 10px;
  }
  .banner_item {
    height: calc((100vw - 30px) / 2.6);
  }
  .grid_sorting_button {
    font-size: 12px;
    padding-left: 10px;
    padding-right: 10px;
    min-width: 60px;
    height: 35px;
  }
  .product-item {
    width: 100%;
    height: auto;
  }
  .product {
    height: auto;
  }
  .product_name a {
    font-size: 12px;
  }
  .add_to_cart_button {
    margin-top: 20px;
  }
  .deal_ofthe_week_img {
    height: 400px;
  }
  .timer {
    margin-top: 42px;
  }
  .timer li {
    width: 50px;
    height: 50px;
  }
  .section_title::after {
    top: calc(100% + 8px);
    height: 3px;
  }
  .timer_num {
    margin-top: 4px;
    font-size: 16px;
  }
  .timer_unit {
    margin-top: -9px;
    font-size: 10px;
  }
  .deal_ofthe_week_button {
    margin-top: 36px;
  }

  .product_slider_item .product-item {
    width: 100%;
    height: auto;
  }
  .product_slider_item .product-item:hover::after {
    box-shadow: none;
  }
  .product_slider_item .product-item .product {
    height: auto;
  }
  .product_slider_item .product-item .product_info {
    padding-bottom: 30px;
  }
  .blog_title {
    font-size: 16px;
  }
  .blog_meta {
    font-size: 10px;
  }
  .blog_more {
    font-size: 12px;
    margin-top: 5px;
  }
  .newsletter_form {
    margin-top: 22px;
  }
  .newsletter_text h4 {
    font-size: 20px;
  }
  .newsletter_text p {
    font-size: 13px;
    margin-top: 5px;
  }
  .newsletter_submit_btn {
    font-size: 12px;
  }
  #newsletter_email {
    height: 40px;
    width: 100%;
    padding-left: 15px;
  }
  .newsletter_submit_btn {
    height: 36px;
    width: 130px;
  }
  #newsletter_email::-webkit-input-placeholder {
    font-size: 12px !important;
    padding-left: 0px;
  }
  #newsletter_email:-moz-placeholder /* older Firefox*/ {
    font-size: 12px !important;
    padding-left: 0px;
  }
  #newsletter_email::-moz-placeholder /* Firefox 19+ */ {
    font-size: 12px !important;
    padding-left: 0px;
  }
  #newsletter_email:-ms-input-placeholder {
    font-size: 12px !important;
    padding-left: 0px;
  }
  #newsletter_email::input-placeholder {
    font-size: 12px !important;
    padding-left: 0px;
  }
  .footer_nav_container {
    margin-top: 35px;
  }
  .cr {
    margin-bottom: 20px;
  }
  .footer_nav {
    margin-bottom: 20px;
  }
  .footer_social {
    margin-top: 5px;
    margin-bottom: 30px;
  }
}

/************
14. 400px
************/

@media only screen and (max-width: 400px) {
}



.fade.right.modal .modal-dialog {
  position: fixed;
  margin: auto;
  width: 320px;
  height: 100%;
  right: 0;
  transform: translate3d(0%, 0, 0);
}

.fade.right.modal .modal-content {
  height: 100%;
  overflow-y: auto;
}

.fade.right.modal .modal-body {
  padding: 15px 15px 80px;
}

/*Right*/
.fade.right.modal {
  right: -320px;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.fade.right.modal.show {
  right: 0;
}

/* ----- MODAL STYLE ----- */
.fade.right.modal .modal-content {
  border-radius: 0;
  border: none;
}

.shopping--cart--container {
  margin-top: 25vh;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  transition: 0.3s;
  border-radius: 10px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
}

.shopping--cart--item {
  margin-top: 20px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eeeeee;
}

.cart--item--img {
  text-align: center;
}
.cart--item--img > img {
  max-height: 150px;
  max-width: 150px;
}



.btn-wide {
  padding-left: 10px;
  padding-right: 10px;
  width: auto;
}
.btn-wait {
  position: relative;
  padding-right: 48px;
  background-repeat: no-repeat;
  background-position: calc(100% - 12px) 6px;
  pointer-events: none;
}
.btn-wide.btn-wait {
  padding-left: 12px;
}
.disabled {
  pointer-events: none;
}
.btn-wait:after,
.btn-wait:before {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  top: calc(50% - 12px);
  right: 12px;
  opacity: 1;
}
.btn-wait:before {
  background-image: url("data:image/svg+xml;charset=UTF-8,%EF%BB%BF%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Ccircle cx='12' cy='12' r='10.186' opacity='.7' fill='none' stroke='white' stroke-width='3.8' stroke-dasharray='1 31 1 11 1 40' stroke-linecap='round' /%3E%3C/svg%3E");
  animation: spin 3.1s linear infinite;
}
.btn-wait:after {
  background-image: url("data:image/svg+xml;charset=UTF-8,%EF%BB%BF%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Ccircle cx='12' cy='12' r='10.186' fill='none' opacity='.7' stroke='white' stroke-width='3.8' stroke-dasharray='1 6 1 20 1 13' stroke-linecap='round' /%3E%3C/svg%3E");
  animation: spin 2.3s linear infinite;
}
.btn-2:before {
  background-image: url("data:image/svg+xml;charset=UTF-8,%EF%BB%BF%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Ccircle cx='12' cy='12' r='10.186' opacity='1' fill='none' stroke='white' stroke-width='3.8' stroke-dasharray='1 20' stroke-linecap='round' /%3E%3C/svg%3E");
}
.btn-2:after {
  background-image: url("data:image/svg+xml;charset=UTF-8,%EF%BB%BF%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Ccircle cx='12' cy='12' r='10.186' fill='none' opacity='1' stroke='white' stroke-width='3.8' stroke-dasharray='1 31' stroke-linecap='round' /%3E%3C/svg%3E");
}
.btn-3:before {
  background-image: url("data:image/svg+xml;charset=UTF-8,%EF%BB%BF%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Ccircle cx='12' cy='12' r='10.186' opacity='1' fill='none' stroke='white' stroke-width='3.8' stroke-dasharray='6 15' stroke-linecap='round' /%3E%3C/svg%3E");
}
.btn-3:after {
  background-image: url("data:image/svg+xml;charset=UTF-8,%EF%BB%BF%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Ccircle cx='12' cy='12' r='10.186' opacity='1' fill='none' stroke='white' stroke-width='3.8' stroke-dasharray='6 15' stroke-linecap='round' /%3E%3C/svg%3E");
  animation: spin 1.3s linear reverse infinite;
}
.btn-4:before {
  background-image: url("data:image/svg+xml;charset=UTF-8,%EF%BB%BF%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Ccircle cx='12' cy='12' r='6' fill='none' stroke='white' stroke-width='3.8' stroke-dasharray='12 99' stroke-linecap='round' /%3E%3C/svg%3E");
  animation: spin 1.1s linear infinite;
}
.btn-4:after {
  background-image: url("data:image/svg+xml;charset=UTF-8,%EF%BB%BF%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Ccircle cx='12' cy='12' r='10.186' fill='none' stroke='white' stroke-width='3.8' stroke-dasharray='12 52' stroke-linecap='round' /%3E%3C/svg%3E");
  animation: spin 1.3s linear reverse infinite;
}
.btn-5:before {
  background-image: none;
}
.btn-5:after {
  background-image: none;
  border: 2px solid white;
  border-radius: 100%;
  border-width: 0 0 3px 3px;
  animation: spin 1.3s linear infinite;
}
.btn-6:before {
  background-image: none;
}
.btn-6:after {
  background-image: url("data:image/svg+xml;charset=UTF-8,%EF%BB%BF%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Ccircle cx='12' cy='12' r='10.186' opacity='.8' fill='none' stroke='white' stroke-width='3.8' stroke-dasharray='32 52' stroke-linecap='round' /%3E%3C/svg%3E");
  animation: spin 1.3s linear infinite;
}
.btn-7:before {
  background-image: none;
}
.btn-7:after {
  background-image: url("data:image/svg+xml;charset=UTF-8,%EF%BB%BF%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Ccircle cx='12' cy='12' r='10.186' opacity='.8' fill='none' stroke='white' stroke-width='3.8' stroke-dasharray='48 52' stroke-linecap='round' /%3E%3C/svg%3E");
  animation: spin 2s cubic-bezier(0, 0.5, 1, 0.5) infinite;
}
.btn-wait.btn-solar {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3e%3ccircle cx='12' cy='12' r='3' fill='yellow' stroke-width='0' /%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: calc(100% - 12px) 6px;
}
.btn-wait.btn-solar:before {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3e%3ccircle cx='12' cy='12' r='10.186' fill='none' stroke='cornflowerblue' stroke-width='3' stroke-dasharray='.1 100' stroke-linecap='round' /%3e%3c/svg%3e");
}
.btn-wait.btn-solar:after {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3e%3ccircle cx='12' cy='12' r='6' fill='none' stroke='grey' stroke-width='2' stroke-dasharray='.1 100' stroke-linecap='round' /%3e%3c/svg%3e");
}
.btn-step:before {
  background-image: none;
}
.btn-step:after {
  background-image: url("data:image/svg+xml;charset=UTF-8,%EF%BB%BF%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Ccircle cx='12' cy='12' r='10.186' opacity='.8' fill='none' stroke='white' stroke-width='3.8' stroke-dasharray='.1 5.233 .1 5.233 .1 5.233  .1 5.233 .1 5.233 .1 5.233 .1  10.333' stroke-linecap='round' /%3E%3C/svg%3E");
  animation: spin 1s steps(12) infinite;
}
.btn-step2:before {
  background-image: none;
}
.btn-step2:after {
  background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3e%3cg fill='none' stroke='white' stroke-width='5' stroke-linecap='round' stroke-dasharray='.1 64'%3e%3ccircle cx='12' cy='12' r='8' opacity='.25' /%3e%3ccircle cx='12' cy='12' r='8' opacity='.5' stroke-dashoffset='-6.28' /%3e%3ccircle cx='12' cy='12' r='8' opacity='.75' stroke-dashoffset='-12.56' /%3e%3ccircle cx='12' cy='12' r='8' opacity='1' stroke-dashoffset='-18.84'/%3e%3c/g%3e%3c/svg%3e");
  animation: spin 1s steps(8) infinite;
}
.btn-step3:before {
  background-image: none;
}
.btn-step3:after {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3e%3cdefs%3e%3clinearGradient id='g' y1='1'%3e%3cstop stop-color='white' offset='0'/%3e%3cstop stop-color='white' stop-opacity='0' offset='1'/%3e%3c/linearGradient%3e%3c/defs%3e%3ccircle cx='12' cy='12' r='8' fill='none' stroke='url(%23g)' stroke-width='5' stroke-linecap='round' stroke-dasharray='.1 6.28 .1 6.28 .1 6.28 .1 99' /%3e%3c/svg%3e");
  animation: spin 1s steps(8) infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.login-form {
  width: 100%;
  border-radius: 4px;
  margin: auto;
}

.form-group {
  position: relative;
  margin-bottom: 15px;
}

.form-control {
  width: 100%;
  height: 50px;
  border: none;
  padding: 5px 7px 5px 15px;
  background: #fff;
  color: #666;
  border: 2px solid #ddd;
  border-radius: 4px;
}
.form-control:focus,
.form-control:focus + .fa {
  border-color: #10ce88;
  color: #10ce88;
}

.form-group .fa {
  position: absolute;
  right: 10px;
  top: 12px;
  color: #999;
}

.log-status.wrong-entry {
  animation: wrong-log 0.3s;
}

.log-status.wrong-entry .form-control,
.wrong-entry .form-control + .fa {
  border-color: #ed1c24;
  color: #ed1c24;
}

.log-btn {
  background-color: #fe4c50 !important;
  dispaly: inline-block;
  width: 100%;
  font-size: 16px;
  height: 40px;
  color: #fff !important;
  text-decoration: none;
  border: none;
  border-radius: 20px !important;
  margin-bottom: 10px;
}

.link {
  text-decoration: none;
  color: #c6c6c6;
  float: right;
  font-size: 12px;
  margin-bottom: 15px;
}
.link:hover {
  text-decoration: underline;
  color: #8c918f;
}

.alert {
  display: none;
  font-size: 12px;
  color: #f00;
  float: left;
}
.modal--close--button {
  position: absolute;
  z-index: 9;
  top: 0;
  right: 0;
  color: #d3d3d3;
  margin-right: 10px;
  cursor: pointer;
}
@keyframes wrong-log {
  0%,
  100% {
    left: 0px;
  }
  20%,
  60% {
    left: 15px;
  }
  40%,
  80% {
    left: -15px;
  }
}

.keen-slider__slide {
  transition: transform 0.05s ease-in-out; /* Adjust timing and easing as needed */
}

/* custom-select-style.css */
.react-dropdown-select-dropdown-handle {
  /* Target dropdown handle element */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
}

.form-control-helper {
  width: 100%;
  height: 100%;
  min-height: 35px;
  padding: 5px 7px 5px 15px;
  background-color: #efefef80;
  border: none;
  border-radius: 8px;
}
.form-control-helper:focus {
  box-shadow: 0 0 3px 1px rgba(251, 186, 27, 0.5);
}

.skeleton {
  background-color: #e0e0e0;
  border-radius: 4px; /* Adjust as needed */
  height: 350px;
  /* cursor: pointer; */
  width: 100%;
  animation: pulse 1.5s ease-in-out infinite alternate;
}

@keyframes pulse {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

.form-group {
  position: relative;
  margin-bottom: 15px;
}

.form-control {
  width: 100%;
  height: 50px;
  border: none;
  padding: 5px 7px 5px 15px;
  background: #fff;
  color: #666;
  border: 2px solid #ddd;
  border-radius: 4px;
}
.form-control:focus,
.form-control:focus + .fa {
  border-color: #10ce88;
  color: #10ce88;
}

.form-group .fa {
  position: absolute;
  right: 10px;
  top: 12px;
  color: #999;
}

.log-status.wrong-entry {
  animation: wrong-log 0.3s;
}

.log-status.wrong-entry .form-control,
.wrong-entry .form-control + .fa {
  border-color: #ed1c24;
  color: #ed1c24;
}

.log-btn {
  background-color: #fe4c50 !important;
  dispaly: inline-block;
  width: 100%;
  font-size: 16px;
  height: 40px;
  color: #fff !important;
  text-decoration: none;
  border: none;
  border-radius: 20px !important;
  margin-bottom: 10px;
}

.link {
  text-decoration: none;
  color: #c6c6c6;
  float: right;
  font-size: 12px;
  margin-bottom: 15px;
}
.link:hover {
  text-decoration: underline;
  color: #8c918f;
}

.alert {
  display: none;
  font-size: 12px;
  color: #f00;
  float: left;
}
.modal--close--button {
  position: absolute;
  z-index: 9;
  top: 0;
  right: 0;
  color: #d3d3d3;
  margin-right: 10px;
  cursor: pointer;
}
@keyframes wrong-log {
  0%,
  100% {
    left: 0px;
  }
  20%,
  60% {
    left: 15px;
  }
  40%,
  80% {
    left: -15px;
  }
}

