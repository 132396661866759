@charset "utf-8";
/* CSS Document */

/******************************

[Table of Contents]

1. 1600px
2. 1440px
3. 1280px
4. 1199px
5. 1024px
6. 991px
7. 959px
8. 880px
9. 768px
10. 767px
11. 539px
12. 479px
13. 400px

******************************/

@media only screen and (max-width: 1600px) {
}

/************
2. 1440px
************/

@media only screen and (max-width: 1540px) {
}

/************
3. 1380px
************/

@media only screen and (max-width: 1380px) {
}

/************
3. 1280px
************/

@media only screen and (max-width: 1280px) {
}

/************
4. 1199px
************/

@media only screen and (max-width: 1199px) {
  .main_slider {
    min-height: 475px;
    height: calc(100vw / 1.714);
  }
  .main_slider_content {
    width: 80%;
  }
  .banner_item {
    height: 220px;
  }
  .product-item {
    height: fit-content;
  }
  .product {
    height: fit-content;
  }
  .timer li {
    width: 90px;
    height: 90px;
  }
  .timer_num {
    font-size: 36px;
    font-weight: 500;
  }
  .timer_unit {
    margin-top: 5px;
  }
  .blog_title {
    font-size: 20px;
  }
  .product_name {
    font-size: 13px;
  }
}

/************
5. 1024px
************/

@media only screen and (max-width: 1024px) {
}

/************
6. 991px
************/

@media only screen and (max-width: 991px) {
  h1 {
    font-size: 48px;
  }
  h2 {
    font-size: 36px;
  }
  .main_slider_content h6 {
    margin-bottom: 23px;
  }
  .shop_now_button {
    margin-top: 26px;
  }
  .grid-item {
    width: 25%;
  }
  .top_nav {
    display: none;
  }
  .navbar_menu {
    display: none;
  }
  .hamburger_container {
    display: block;
  }
  .main_slider {
    margin-top: -50px;
  }
  .main_slider_content {
    width: 100%;
    padding-right: 15px;
  }
  .banner_item {
    height: 160px;
  }
  .banner_category {
    min-width: 160px;
    height: 40px;
  }
  .banner_category a {
    font-size: 16px;
    line-height: 40px;
  }

  .deal_ofthe_week_col {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .deal_ofthe_week_content {
    position: relative;
    top: auto;
    left: auto;
    height: auto;
  }
  .deal_ofthe_week_img {
    text-align: center;
  }
  .timer li {
    width: 70px;
    height: 70px;
  }
  .timer_num {
    font-size: 28px;
  }
  .timer_unit {
    margin-top: 1px;
    font-size: 14px;
  }
  .benefit_col {
    margin-bottom: 30px;
  }
  .benefit_col:last-child {
    margin-bottom: 0px;
  }
  .blog_item_col {
    margin-bottom: 30px;
  }
  .blog_item_col:last-child {
    margin-bottom: 0px;
  }
  .blog_item {
    height: 440px;
  }
  .blog_title {
    font-size: 24px;
  }
  .newsletter_text {
    height: auto;
    margin-top: 30px;
  }
  .newsletter_text p {
    margin-top: 5px;
  }
  .newsletter_form {
    height: auto;
    margin-top: 30px;
    margin-bottom: 40px;
  }
  .footer_nav_container {
    height: auto;
    margin-top: 65px;
  }
  .footer_social {
    height: auto;
    margin-top: 20px;
    margin-bottom: 65px;
  }

  .single_product_thumbnails > ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex: auto;
  }
  .single_product_thumbnails {
    height: auto;
  }
  .single_product_thumbnails ul li {
    position: relative;
    height: 130px;
    width: 20%;
    margin-bottom: 6px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  .product_name {
    font-size: 12px;
  }
}

/************
7. 959px
************/

@media only screen and (max-width: 959px) {
}

/************
8. 880px
************/

@media only screen and (max-width: 880px) {
}

/************
9. 768px
************/

@media only screen and (max-width: 768px) {
  .product_name {
    font-size: 13px;
  }
}

/************
10. 767px
************/

@media only screen and (max-width: 767px) {
  h1 {
    font-size: 36px;
  }
  h2 {
    font-size: 24px;
  }
  h6 {
    font-size: 12px;
  }
  .main_slider_content h6 {
    margin-bottom: 20px;
  }
  .shop_now_button {
    margin-top: 23px;
  }
  .red_button a {
    font-size: 12px;
  }
  .banner_item {
    margin-bottom: 30px;
    height: 210px;
  }
  .new_arrivals_title {
    margin-top: 44px;
  }
  .product-item {
    height: fit-content;
  }
  .product {
    height: fit-content;
  }
  .grid_sorting_button {
    font-size: 13px;
    padding-left: 20px;
    padding-right: 20px;
    min-width: 80px;
  }
  .product_slider_container {
    height: auto;
  }
  .product_slider_item .product-item {
    height: 380px;
  }
  .benefit_col {
    margin-bottom: 15px;
  }
  .blog_item {
    height: 372px;
  }
  .newsletter_form {
    margin-bottom: 40px;
  }
  .newsletter_submit_btn {
    margin-top: 15px;
  }
  .product_image img {
    max-width: 100%; /* Ensures image doesn't exceed container width */
    /* object-fit: contain; */
  }
}

/************
11. 575px
************/

@media only screen and (max-width: 575px) {
  .hamburger_menu {
    right: -100%;
    width: 100%;
  }
  .product-item {
    height: fit-content;
  }
  .product {
    height: fit-content;
  }
  .blog_item {
    height: calc((100vw - 30px) / 1.37);
  }
  .cr {
    display: block;
    margin-right: 0px;
    margin-bottom: 30px;
    font-size: 13px;
  }
  .footer_nav {
    margin-bottom: 20px;
  }
  .footer_nav li {
    display: block;
    margin-right: 0px;
  }
  .footer_nav li a {
    font-size: 13px;
  }
  .product_name {
    font-size: 16px;
  }
}

/************
11. 539px
************/

@media only screen and (max-width: 539px) {
}

/************
12. 480px
************/

@media only screen and (max-width: 480px) {
}

/************
13. 479px
************/

@media only screen and (max-width: 479px) {
  .logo_container a {
    font-size: 12px;
  }
  .hamburger_container {
    margin-left: 25px;
  }
  .hamburger_container i {
    font-size: 16px;
  }
  .hamburger_close {
    top: 14px;
    right: 4px;
  }
  .hamburger_close i {
    font-size: 20px;
  }
  .hamburger_menu_content {
    padding-right: 15px;
    padding-top: 70px;
  }
  .menu_item {
    border-bottom-color: rgba(181, 174, 196, 0.5);
  }
  .menu_item > a {
    font-size: 12px;
    line-height: 35px;
    height: 35px;
  }
  .menu_selection li a {
    font-size: 12px;
    line-height: 35px;
    height: 35px;
  }
  .navbar {
    height: 70px;
  }
  .navbar_user li a {
    width: auto;
    height: 30px;
    font-size: 12px;
  }
  .checkout_items {
    width: 15px;
    height: 15px;
    font-size: 10px;
  }
  .main_slider {
    height: calc(100vh - 70px);
    /*height: 80vw;*/
    min-height: auto;
    margin-top: 70px;
  }
  .main_slider_content h6 {
    margin-bottom: 15px;
  }
  .main_slider_content h1 {
    font-size: 24px;
  }
  .shop_now_button {
    margin-top: 15px;
    width: 100px;
    height: 35px;
  }
  .red_button a {
    font-size: 10px;
  }
  .banner_item {
    height: calc((100vw - 30px) / 2.6);
  }
  .grid_sorting_button {
    font-size: 12px;
    padding-left: 10px;
    padding-right: 10px;
    min-width: 60px;
    height: 35px;
  }
  .product-item {
    width: 100%;
    height: auto;
  }
  .product {
    height: auto;
  }
  .product_name a {
    font-size: 12px;
  }
  .add_to_cart_button {
    margin-top: 20px;
  }
  .deal_ofthe_week_img {
    height: 400px;
  }
  .timer {
    margin-top: 42px;
  }
  .timer li {
    width: 50px;
    height: 50px;
  }
  .section_title::after {
    top: calc(100% + 8px);
    height: 3px;
  }
  .timer_num {
    margin-top: 4px;
    font-size: 16px;
  }
  .timer_unit {
    margin-top: -9px;
    font-size: 10px;
  }
  .deal_ofthe_week_button {
    margin-top: 36px;
  }

  .product_slider_item .product-item {
    width: 100%;
    height: auto;
  }
  .product_slider_item .product-item:hover::after {
    box-shadow: none;
  }
  .product_slider_item .product-item .product {
    height: auto;
  }
  .product_slider_item .product-item .product_info {
    padding-bottom: 30px;
  }
  .blog_title {
    font-size: 16px;
  }
  .blog_meta {
    font-size: 10px;
  }
  .blog_more {
    font-size: 12px;
    margin-top: 5px;
  }
  .newsletter_form {
    margin-top: 22px;
  }
  .newsletter_text h4 {
    font-size: 20px;
  }
  .newsletter_text p {
    font-size: 13px;
    margin-top: 5px;
  }
  .newsletter_submit_btn {
    font-size: 12px;
  }
  #newsletter_email {
    height: 40px;
    width: 100%;
    padding-left: 15px;
  }
  .newsletter_submit_btn {
    height: 36px;
    width: 130px;
  }
  #newsletter_email::-webkit-input-placeholder {
    font-size: 12px !important;
    padding-left: 0px;
  }
  #newsletter_email:-moz-placeholder /* older Firefox*/ {
    font-size: 12px !important;
    padding-left: 0px;
  }
  #newsletter_email::-moz-placeholder /* Firefox 19+ */ {
    font-size: 12px !important;
    padding-left: 0px;
  }
  #newsletter_email:-ms-input-placeholder {
    font-size: 12px !important;
    padding-left: 0px;
  }
  #newsletter_email::input-placeholder {
    font-size: 12px !important;
    padding-left: 0px;
  }
  .footer_nav_container {
    margin-top: 35px;
  }
  .cr {
    margin-bottom: 20px;
  }
  .footer_nav {
    margin-bottom: 20px;
  }
  .footer_social {
    margin-top: 5px;
    margin-bottom: 30px;
  }
}

/************
14. 400px
************/

@media only screen and (max-width: 400px) {
}
