.form-group {
  position: relative;
  margin-bottom: 15px;
}

.form-control {
  width: 100%;
  height: 50px;
  border: none;
  padding: 5px 7px 5px 15px;
  background: #fff;
  color: #666;
  border: 2px solid #ddd;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
.form-control:focus,
.form-control:focus + .fa {
  border-color: #10ce88;
  color: #10ce88;
}

.form-group .fa {
  position: absolute;
  right: 10px;
  top: 12px;
  color: #999;
}

.log-status.wrong-entry {
  -moz-animation: wrong-log 0.3s;
  -webkit-animation: wrong-log 0.3s;
  animation: wrong-log 0.3s;
}

.log-status.wrong-entry .form-control,
.wrong-entry .form-control + .fa {
  border-color: #ed1c24;
  color: #ed1c24;
}

.log-btn {
  background-color: #fe4c50 !important;
  dispaly: inline-block;
  width: 100%;
  font-size: 16px;
  height: 40px;
  color: #fff !important;
  text-decoration: none;
  border: none;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  border-radius: 20px !important;
  margin-bottom: 10px;
}

.link {
  text-decoration: none;
  color: #c6c6c6;
  float: right;
  font-size: 12px;
  margin-bottom: 15px;
}
.link:hover {
  text-decoration: underline;
  color: #8c918f;
}

.alert {
  display: none;
  font-size: 12px;
  color: #f00;
  float: left;
}
.modal--close--button {
  position: absolute;
  z-index: 9;
  top: 0;
  right: 0;
  color: #d3d3d3;
  margin-right: 10px;
  cursor: pointer;
}

@-moz-keyframes wrong-log {
  0%,
  100% {
    left: 0px;
  }
  20%,
  60% {
    left: 15px;
  }
  40%,
  80% {
    left: -15px;
  }
}
@-webkit-keyframes wrong-log {
  0%,
  100% {
    left: 0px;
  }
  20%,
  60% {
    left: 15px;
  }
  40%,
  80% {
    left: -15px;
  }
}
@keyframes wrong-log {
  0%,
  100% {
    left: 0px;
  }
  20%,
  60% {
    left: 15px;
  }
  40%,
  80% {
    left: -15px;
  }
}
