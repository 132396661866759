/* custom-select-style.css */
.react-dropdown-select-dropdown-handle {
  /* Target dropdown handle element */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
}

.form-control-helper {
  width: 100%;
  height: 100%;
  min-height: 35px;
  padding: 5px 7px 5px 15px;
  background-color: #efefef80;
  border: none;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
}
.form-control-helper:focus {
  box-shadow: 0 0 3px 1px rgba(251, 186, 27, 0.5);
}

.skeleton {
  background-color: #e0e0e0;
  border-radius: 4px; /* Adjust as needed */
  height: 350px;
  /* cursor: pointer; */
  width: 100%;
  animation: pulse 1.5s ease-in-out infinite alternate;
}

@keyframes pulse {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
